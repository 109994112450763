import Amplify, { Auth, Cache, Hub, Logger, API } from "aws-amplify";
import { navigate } from "@reach/router";
import { CODE_SIGN_IN, CODE_SIGN_UP, CODE_RESEND, CODE_COGNITO, CODE_RESET } from '../components/authcode'
import { handleBlock, handleAddUser, handleUpdateUser } from "./api";



// SET UP AMPLIFY CONFIGURATION
Amplify.configure({
  ssr: true,
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: `${process.env.IDENTITY_POOL_ID}`,

    // REQUIRED - Amazon Cognito Region
    region: `${process.env.REGION}`,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: `${process.env.USER_POOL_ID}`,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: `${process.env.USER_POOL_WED_CLIENT_ID}`,

  },
  Storage: {
    bucket: `${process.env.S3_BUCKET}`, //REQUIRED -  Amazon S3 bucket
    region: `${process.env.REGION}`, //OPTIONAL -  Amazon service region
    identityPoolId: `${process.env.USER_POOL_ID}`
  },
  API: {
    endpoints: [
      {
        name: "sesApi",
        endpoint: `${process.env.SES_GATEWAY}`,
        region: `${process.env.REGION}`
      },
      {
        name: "rdsApi",
        endpoint: `${process.env.RDS_GATEWAY}`,
        region: `${process.env.REGION}`
      },
      {
        name: "privateSes",
        endpoint: `${process.env.SES_GATEWAY}`,
        region: `${process.env.REGION}`,
        custom_header: async () => {
          return { Authorization: (await Auth.currentSession()).idToken.jwtToken }
        }
      },
      {
        name: "privateApi",
        endpoint: `${process.env.RDS_GATEWAY}`,
        region: `${process.env.REGION}`,
        custom_header: async () => {
          return { Authorization: (await Auth.currentSession()).idToken.jwtToken }
        }
      },
      {
        name: "userApi",
        endpoint: `${process.env.RDS_GATEWAY}`,
        region: `${process.env.REGION}`,
        custom_header: async () => {
          return { Authorization: (await Auth.currentSession()).idToken.jwtToken }
        }
      }

    ]
  }
})

if (typeof window !== "undefined") {
  Auth.configure({ storage: window.sessionStorage })
}

const logger = new Logger('Auth Logger');
const listener = (data) => {

  switch (data.payload.event) {

    case 'signIn':
      logger.info('user signed in'); //[ERROR] My-Logger - user signed in
      break;
    case 'signUp':
      logger.error('user signed up');
      break;
    case 'signOut':
      logger.error('user signed out');
      break;
    case 'signIn_failure':
      logger.error('user sign in failed');
      break;
    case 'configured':
      logger.error('the Auth module is configured');

  }
}
// Hub.listen('auth', listener);

if (typeof window !== "undefined") {
  // window.LOG_LEVEL = 'DEBUG'
}

export const isBrowser = () => typeof window !== "undefined"

// DO NOT PUT AN ASYNC TO THIS FUNCTION OR YOU WILL PULL YOUR HAIR OUT FIGURING OUT WTF IS WRONG
// SNEAKY LITTLE BASTARD
// IT WILL NOT LOG OUT THE USER IF PLACED CAUSE OF THE ASYNCHRONOUS TASK
export const isLoggedIn = () => {
  let user = null
  //console.log('isLoggedIn')
  if (!Cache.getItem("user") || Cache.getItem("user") === null || isEmpty(Cache.getItem("user"))) {
    //Auth.signOut({global:true})
    //console.log('isLoggedIn - empty user');
    user = false
  } else {
    user = Cache.getItem("user")

    if (Cache.getItem('expired') || Cache.getItem('expired') === null) {
      navigate('/account/profile/', { state: { status: "warning", statusMessage: "Password has expired. You are required to update your password." } })
    }
  }

  return !!user

}

export const isMFauth = () => {
  if (Cache.getItem("user" != null && Cache.getItem("ma") != null)) {
    return true
  } else {
    return false
  }
}

export const handleMFauth = async (data) => {

  let init = {
    body: {
      "email": data.email,
      "token": data.token
    }
  }

  var endpoint = `${process.env.API_ENV}/mfauth/auth`

  await API.post('userApi', endpoint, init)
    .then((res) => {
      console.log(res)
      // Cache.setItem("otpToken", "")
      // Cache.setItem("ma", 1)
      if (res.success && res.token) {
        Cache.setItem("ma", 1)
        Cache.setItem("otpToken", res.token || "")
      } else {
        Cache.setItem("ma", 0)
        Cache.setItem("otpToken", "")
      }
      // if (res == "Failed") {
      //   Cache.setItem("ma", 0)
      // } else {
      //   Cache.setItem("ma", 1)
      // }
    })
    .catch(err => console.log(err))


}


export const handleMFtrigger = async (data) => {
  data = data.replace('"', '');
  let init = {
    body: data
  }



  var endpoint = `${process.env.API_ENV}/mfauth/trigger`

  await API.post('userApi', endpoint, init)
    .then(() => {
      Cache.setItem("matrigger", 1)
    })
    .catch(err => console.log(err))



  return

}


const setUser = user => {
  var dt = new Date()
  dt.setMinutes(dt.getMinutes() + 60)

  window.localStorage.setItem("gatsbyUser", JSON.stringify(user))
  Cache.setItem("user", user, { expires: dt.getTime() })
}

export const getUser = () =>
  isBrowser() && window.localStorage.getItem("gatsbyUser") && Auth.currentUserInfo()
    ? JSON.parse(window.localStorage.getItem("gatsbyUser"))
    : null

export const getCurrentUser = () => isBrowser && getUser()

export const handleLogin = async ({ email, password }) => {
  if (!isBrowser) return false

  let resp = {
    code: "",
    message: ""
  }

  await Auth.signIn(email.toLowerCase(), password)
    .then(async (res) => {
      await Auth.currentUserInfo()
        .then((userData) => {

          if (userData === '' || JSON.stringify(userData) === '{}') {
            console.log('Concurrent Session')
            return resp.code = CODE_COGNITO.PROCESS_INVALID, resp.message = "Concurrent Session"
          }
          var dt = new Date()
          dt.setMinutes(dt.getMinutes() + 60)

          Cache.setItem("user", userData, { expires: dt.getTime() })

          var dateDiff = dt.valueOf() - Cache.getItem('user').attributes['custom:last_reset']


          if (dateDiff > 60e3) {
            var minuteDiff = Math.floor(dateDiff / 60e3)

            if (minuteDiff > 60 * 24 * 90) { // minutes * hours * days = greater than 1 day
              Cache.setItem("expired", true, { expires: dt.getTime() })
            } else {
              Cache.setItem("expired", false, { expires: dt.getTime() })
            }
          } else {
            Cache.setItem("expired", false, { expires: dt.getTime() })
          }

          setUser({
            userData
          }, () => { console.log('Set userdata') })

          return resp.code = CODE_COGNITO.PROCESS_VALID
        })
        .catch((err) => {
          console.log(err)
          console.log('error occured at auth.currentUserInfo()')
          return resp.code = CODE_COGNITO.PROCESS_INVALID, resp.message = "Error Occured"
        })
    })
    .catch(async (err) => {
      console.log('Login Error')
      console.log(err)
      switch (err.code) {
        case CODE_SIGN_IN.USER_NOT_FOUND:
          resp.code = CODE_SIGN_IN.USER_NOT_FOUND
          resp.message = err.message

          break;

        case CODE_SIGN_IN.USER_INCORRECT_PASSWORD:
          resp.code = CODE_SIGN_IN.USER_INCORRECT_PASSWORD
          resp.message = err.message
          break;

        case CODE_SIGN_IN.USER_NOT_VERIFIED:
          resp.code = CODE_SIGN_IN.USER_NOT_VERIFIED
          resp.message = err.message
          break

        case CODE_SIGN_IN.USER_LAMBDA_BLOCKED:
          resp.code = CODE_SIGN_IN.USER_LAMBDA_BLOCKED
          resp.message = err.message
          break

        default:
          console.log(err)
          resp.code = false
          resp.message = err
          break;
      }
    });

  return resp;


}

export const isAuthenticated = async callback => {
  await Auth.currentAuthenticatedUser()
    .then(async (user) => {
      console.log(user)
      if (user === '' || JSON.stringify(user) === '{}') {
        await logout()
        navigate('/signin/')
      } else {
        console.log('success')
        return true;
      }
    }).catch(async (err) => {
      console.log(err);
      await logout()
      navigate('/signin/')
    })
}

export const logout = async callback => {
  if (!isBrowser) return


  await Auth.currentAuthenticatedUser()
    .then(async (user) => {
      console.log('Logging out...')
      var dt = new Date()
      dt.setMinutes(dt.getMinutes() - 60)
      await Auth.updateUserAttributes(user, {
        'custom:c1': String(dt),
      })
        .then(() => {
          console.log('Successfully logged out!')
          setUser({})
          Auth.signOut({ global: true })
          Cache.clear()
          callback()
        })
        .catch(err => {
          console.log(err)
          if (err.code == 'NotAuthorizedException' || err.code == 'UserNotFoundException') {
            setUser({})
            Cache.clear()
            callback()
          }
        })
    })
    .catch((err) => {
      console.log('Logging out.')
      console.log(err)
      setUser({})
      Cache.clear()
      callback()
    })

}

export const handleRegistration = async ({ email, password, firstName, lastName, country, contact, orgName, api, events, captcha }) => {

  if (!isBrowser) return false

  let flags = {
    err: "",
    isValid: false,
    code: ""
  }

  var dt = new Date()
  dt.setMinutes(dt.getMinutes() - 60)

  await Auth.signUp({
    username: email.toLowerCase(),
    password: password,
    attributes: {
      'email': email.toLowerCase(),
      'phone_number': contact,
      'name': [escapeRegExp(firstName), escapeRegExp(lastName)].join(' '),
      'family_name': escapeRegExp(lastName),
      'given_name': escapeRegExp(firstName),
      'custom:country': ['en', country].join('-'),
      'custom:organization': escapeRegExp(orgName),
      'custom:newsletter': String(events),
      'custom:api-update': String(api),
      'custom:role': CODE_SIGN_UP.USER_ROLE,
      'custom:attempt': "5",
      'custom:blocked': "0",
      'custom:last_reset': String((new Date).valueOf()),
      'custom:last_attempt': String((new Date).toLocaleDateString()),
      'custom:c1': String(dt)
    },
    validationData: {
      'captcha': captcha
    },

  })
    .then(async () => {
      //add user to data
      let userParam = {
        'email': email.toLowerCase()
      }
      await handleAddUser(userParam)
        .then((res) => console.log(res))
        .catch(err => console.log(err))

      console.log('Sign up successful')
      flags.isValid = CODE_COGNITO.PROCESS_VALID
      flags.code = CODE_COGNITO.PROCESS_VALID
      Auth.signOut({ global: true })
      navigate('/confirmation/');
    })
    .catch((err) => {
      console.log('Sign up failed')
      console.log(err.message)
      switch (err.code) {
        case CODE_SIGN_UP.USER_EXISTS:
          flags.isValid = CODE_COGNITO.PROCESS_INVALID
          flags.err = err.message
          flags.code = CODE_SIGN_UP.USER_EXISTS
          break;

        case CODE_COGNITO.NETWORK_ERROR:
          flags.isValid = CODE_COGNITO.PROCESS_INVALID
          flags.err = err.message
          flags.code = CODE_SIGN_UP.NETWORK_ERROR
          break

        case CODE_SIGN_UP.ATTRIBUTE_ERROR:
          flags.isValid = CODE_COGNITO.PROCESS_INVALID
          flags.err = err.message
          flags.code = CODE_SIGN_UP.ATTRIBUTE_ERROR
          break

        default:
          flags.isValid = false;
          flags.code = true;
          break
      }

    })
  return flags

}

export const handleBlocking = async ({ email }) => {
  await handleBlock(email)
    .then(() => {
      return true
    })
    .catch(err => console.log(err))
}

export const handleResend = async ({ email }) => {
  let flags = {
    isValid: false,
    code: "",
    message: "",
    type: ""
  }

  email = email.toLowerCase();
  await Auth.forgotPassword(email)
    .then((data) => {
      flags.isValid = CODE_COGNITO.PROCESS_VALID
      flags.message = "A code has been sent to " + data.CodeDeliveryDetails.Destination + " . "
      flags.code = "EmailCodeResent"
      flags.type = "forgot"
    })
    .catch(async (err) => {
      console.log(err)
      switch (err.code) {
        case CODE_RESEND.USER_NOT_FOUND:
          flags.isValid = false
          flags.message = "Cannot find the provided the email. Please double check the information you are trying to provide."
          flags.code = CODE_RESEND.USER_NOT_FOUND
          flags.type = "forgot"

          break;

        case CODE_RESEND.USER_LIMIT_EXCEED:
          flags.isValid = false
          flags.message = "Attempt limit exceeded, please try after some time."
          flags.code = CODE_RESEND.USER_LIMIT_EXCEED
          flags.type = "forgot"

          break

        case CODE_RESEND.USER_NOT_VERIFIED:
          await Auth.resendSignUp(email)
            .then((res) => {
              if (res.code === "InvalidParameterException") {
                flags.isValid = false
                flags.message = "Cannot find the provided the email. Please double check the information you are trying to provide."
                flags.code = CODE_RESEND.USER_NOT_VERIFIED
                flags.type = "resend"
              } else {
                flags.isValid = true
                flags.message = "A verification link has been resent to your account. Do note that the verification link is only valid for 24 hours."
                flags.code = CODE_RESEND.USER_NOT_VERIFIED
                flags.type = "resend"
              }
            })
            .catch(err => {
              flags.isValid = false
              flags.message = "Cannot find the provided the email. Please double check the information you are trying to provide."
              flags.code = CODE_RESEND.USER_NOT_VERIFIED
              flags.type = "resend"

            })

          break

        default:
          flags.isValid = false
          flags.message = "An unknown error has been encountered"
          flags.code = CODE_COGNITO.UNKNOWN_ERROR
          flags.type = "forgot"
          break
      }
    })

  return flags;

}

export const handleReset = async (data) => {
  if (!isBrowser) return false

  // window.LOG_LEVEL = 'DEBUG'


  let flags = {
    isValid: false,
    code: "",
    message: ""
  }

  data.email = data.email.toLowerCase();
  // Check if the same password as previous
  await Auth.signIn(data.email, data.password)
    .then(async () => {

      flags.isValid = CODE_COGNITO.PROCESS_INVALID
      flags.code = CODE_RESET.RESET_PASSWORD

      // await Auth.signOut({global: true})
      await logout()

      return flags
    })
    .catch(async (res) => {

      await Auth.forgotPasswordSubmit(data.email, data.vercode, data.password)
        .then(async (res) => {

          flags.isValid = CODE_COGNITO.PROCESS_VALID
        })
        .catch(async (err) => {
          switch (err.code) {
            case CODE_RESET.RESET_EXPIRED:
              flags.isValid = CODE_COGNITO.PROCESS_INVALID
              flags.code = CODE_RESET.RESET_EXPIRED

              break;

            case CODE_RESET.RESET_MISMATCH:
              flags.isValid = CODE_COGNITO.PROCESS_INVALID
              flags.code = CODE_RESET.RESET_MISMATCH
              break
            default:
              flags.isValid = CODE_COGNITO.PROCESS_INVALID
              break;
          }
        })
    })

  return flags
}


export const handleVerify = async (data) => {

  let flags = {
    isValid: false,
    code: "",
    message: ""
  }
  let username = data.username;
  await Auth.confirmSignUp(data.username, data.code)
    .then(async (data) => {

      flags.isValid = CODE_COGNITO.PROCESS_VALID
      await handleUpdateUser(username)
        .then((res) => console.log(res))
        .catch(err => console.log(err))
    })
    .catch((err) => {
      switch (err) {
        case CODE_COGNITO.USER_NOT_FOUND:
          flags.isValid = CODE_COGNITO.PROCESS_INVALID
          flags.code = CODE_COGNITO.USER_NOT_FOUND
          flags.message = err.message
          break;

        case CODE_COGNITO.PROCESS_LIMIT:
          flags.isValid = CODE_COGNITO.PROCESS_INVALID
          flags.code = CODE_COGNITO.PROCESS_LIMIT
          flags.message = err.message
          break;

        default:
          flags.isValid = CODE_COGNITO.PROCESS_INVALID
          break;
      }

    })

  return flags
}

export const handleProfileUpdate = async ({ firstName, lastName, country, contact, orgName, api, events }) => {

  if (!isBrowser) return false

  let flags = {
    code: "",
    isValid: false,
    message: ""
  }
  let user = await Auth.currentAuthenticatedUser();

  if (!user) {
    return flags = { isValid: false, message: "Invalid user. Please relog into the system" }
  }

  // CHECK IF THERE WAS A PASSWORD CHANGE
  await Auth.updateUserAttributes(user, {
    'phone_number': contact,
    'name': [escapeRegExp(firstName), escapeRegExp(lastName)].join(' '),
    'family_name': escapeRegExp(lastName),
    'given_name': escapeRegExp(firstName),
    'custom:country': ['en', country].join('-'),
    'custom:organization': escapeRegExp(orgName),
    'custom:newsletter': String(events),
    'custom:api-update': String(api),
  })
    .then(async (data) => {

      await Auth.currentUserInfo()
        .then((userData) => {
          setUser({ userData })
        })

      await handleUpdateUser(user.username)
        .then((res) => console.log(res))
        .catch(err => console.log(err))

      flags.isValid = true
    })
    .catch((err) => {
      console.log(err)
      flags = {
        isValid: false,
        message: err.message
      }
    })

  return flags
}

// Change Password
export const handleChangePassword = async ({ oldPassword, password }) => {

  if (!isBrowser) return false

  let dt = new Date()
  let flags = {
    code: "",
    isValid: false,
    message: ""
  }
  let user = await Auth.currentAuthenticatedUser();

  if (!user) {
    return flags = { isValid: false, message: "Invalid user. Please relog into the system" }
  }

  // ATTEMPT TO CHANGE PASSWORD BEFORE PROCEEDING
  await Auth.changePassword(user, oldPassword, password)
    .then(async () => {
      console.log("Password changed!")
      flags.isValid = true

      await Auth.updateUserAttributes(user, {
        'custom:last_reset': String(dt.valueOf()),
        'custom:c1': String(new Date()),
      })
        .then(() => {
          dt.setMinutes(dt.getMinutes() + 60)
          Cache.setItem("expired", false, { expires: dt.getTime() })
        })
    })
    .catch((err) => {
      console.log(err)
      flags = {
        code: err.code,
        isValid: false,
        message: err.message
      }
    })

  return flags

}


// CUSTOM FUNCTIONS
function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|<>='";:[\]\\\/]/g, '');
}

function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key))
      return false;
  }
  return true;
}